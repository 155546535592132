import React from 'react';
import { AppConst } from "../../constants";
import googlePlayButton from "../../assets/images/google-play-button.png";
import appStoreButton from "../../assets/images/app-store-button.png";

const Footer = () => {
    return (
        <footer className="text-center font-size-14">
            <div className="container">
                <p>Install the Payswitch app</p>
                <div className='w-100 mb-3'>
                    <a href="https://play.google.com/store/apps/details?id=com.quidvis.payswitch.payswitch"
                       target="_blank" rel="noreferrer" className='m-1'>
                        <img className={`img-fluid w-auto h-auto`} style={{ maxWidth: '40%' }} src={googlePlayButton} alt={`logo`}/>
                    </a>
                    <a href="https://apps.apple.com/ng/app/payswitch/id6476865861" target="_blank" rel="noreferrer" className='m-1'>
                        <img className={`img-fluid w-auto h-auto`} style={{ maxWidth: '40%' }} src={appStoreButton} alt={`logo`}/>
                    </a>
                </div>
                <p>
                    {AppConst.APP_NAME} © {AppConst.APP_YEAR}.
                    {` `}<a href={'https://www.payswitch.africa/privacy'} className={`text-dark color-accent-hover`}>Privacy
                    Policy</a> |
                    {` `}<a href={'https://www.payswitch.africa/terms'} className={`text-dark color-accent-hover`}>Terms
                    and
                    Conditions</a>
                </p>
            </div>
        </footer>
    );
}

export default Footer;
